import Service from "./service";

class GeneralService extends Service {
    constructor() {
        super()
    }

    async getDashboardData() {
        return await this.ApiClient.get(`/getDashboardData`);
    }
    async getTop10Trades(data) {
        return await this.ApiClient.get(`/getTop10Trades?mode=${data}`, {
            headers: {
                'Connection': 'keep-alive'
            },
            timeout: 60000 * 10
        });
    }
    async getTop10IndainTrades(data) {
        return await this.ApiClient.get(`/getTop10IndainTrades?mode=${data}`, {
            headers: {
                'Connection': 'keep-alive'
            },
            timeout: 60000 * 10
        });
    }
    async getCompanyDetailData(data) {
        return await this.ApiClient.get(`/getCompanyDetailData?company=${data.company}&date_from=${data.date_from}&date_to=${data.date_to}`, {
            headers: {
                'Connection': 'keep-alive'
            },
            timeout: 60000 * 10
        });
    }
    async getTradeData(data) {
        return await this.ApiClient.get(`/getTradeData${data.queryParams}`);
    }
    async getTradeDetail(tradeId, queryParams) {
        return await this.ApiClient.get(`/getTradeDetail/${tradeId}${queryParams}`);
    }
    async getTradeHistoryChartData(code, queryParams,signal) {
        return await this.ApiClient.get(`/getTradeHistoryChartData/${code}?mode=${queryParams}`,{signal});
    }
    async getSectorData() {
        return await this.ApiClient.get(`/getSectorData`);
    }
    async getSectorDetail(sectorName, queryParams) {
        return await this.ApiClient.get(`/getSectorDetail/${sectorName}${queryParams}`);
    }
    async getCompaniesData(data) {
        return await this.ApiClient.get(`/getCompaniesData${data.queryParams}`);
    }
    async getCompaniesDetail(companyId, queryParams) {
        return await this.ApiClient.get(`/getCompaniesDetail/${companyId}`);
    }
    async getWatchlistData(data) {
        return await this.ApiClient.get(`/getWatchlistData${data.queryParams}`);
    }
    async deleteWatchlistRowData(code) {
        return await this.ApiClient.delete(`/deleteWatchlistRowData/${code}/delete`);
    }
    async getNewsData(data) {
        return await this.ApiClient.get(`/getNewsData${data.queryParams}`);
    }
    async getGptResponse(data) {
        return await this.ApiClient.post(`/getGptResponse`, data.body);
    }
    async getDataromaResponse(params = {}) {
        return await this.ApiClient.get(`/dataroma?limit=${params.limit || 10}&page=${params.page || 1}&type=${params.type || ''}`);
    }
}

export default GeneralService;